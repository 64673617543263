import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/htdlogo2.png';
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="htd__navbar">
        <div className="htd__navbar-links_container">
          <p><a href="#home">HOME</a></p>
          <p><a href="#about">STORE</a></p>
          <p><a href="#contact">CONTACT</a></p>
      </div>
    </div>
  
  );
};

export default Navbar;