import React from 'react'
import './brands.css'
import threebrands from '../../assets/threebrand.png'

const Brands = () => {
  return (
    <div className='htd_brands' id='about'>
      <img src = {threebrands} />
    </div>
  )
}

export default Brands