import React from 'react';
import googlemaps from '../../assets/gmaps.png'
import './about.css'

const About = () => {
  return <div>
      <div className='htd__about'>
        <h1>Come Visit Us</h1>
        <p>179 Carniny Rd, Unit 6, Ballymena, BT43 5NJ</p>
        <p>(GOOGLE MAPS)</p>
        <a href='https://www.google.co.uk/maps/place/CARNINY+BUSINESS+PARK/@54.8835205,-6.3102721,17z/data=!3m1!4b1!4m5!3m4!1s0x4861b33e73867aed:0xd08f9431a3c125a1!8m2!3d54.8835205!4d-6.3080834'>
          <img src={googlemaps} />
        </a>
        <div id='contact'>
        <h1>CONTACT US</h1>
        <a href='tel:+447734907434'>Mobile: +447734907434</a>
        <a href='mailto:paul@hitthediff.co.uk'>Email: paul@hitthediff.co.uk</a>
       </div>
      </div>

    

  </div>;
};

export default About;
