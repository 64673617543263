import React from 'react';
import './header.css';

const Header = () => {
    return (
        <div className= "htd__header">
            <div className="htd__header-content">
                <p><strong>TRACTOR PARTS</strong><br/>Specialists In All Makes And Models Of Tractors </p>
            </div>
        </div>
    )
}

export default Header
