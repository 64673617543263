import React from 'react'
import './topbar.css'
import logo from '../../assets/htdlogo2.png'

const Topbar  = () => {
  return (
    <div className='htd__topbar' id='home'>
        <div className='htd__topbar-logo'>
            <img src={logo} />
            <h1>HIT THE DIFF</h1>
        </div>
        <div className='htd__topbar-info'>
            <a href='tel:+447734907434'>Mobile: +447734907434</a>
            <a href='mailto:paul@hitthediff.co.uk'>Email: paul@hitthediff.co.uk</a>
        </div>
    </div>
  )
}

export default Topbar;