import React from 'react';
import './contact.css'
import whatsapp from '../../assets/whatsappicon.png'
import facebook from '../../assets/facebookicon.png'
import email from '../../assets/emailicon.png'
const Contact = () => {
  return <div>

      <div className='about__icons'>
        <a href='https://wa.me/447734907434'>
          <img src={whatsapp} />
        </a>

        <a href='https://www.facebook.com/hitthedifftractorparts/'>
          <img src={facebook} />
        </a>

        <a href='mailto:paul@hitthediff.co.uk'>
        <img src={email} />
        </a>
      </div>
  </div>;
};

export default Contact;

