import React from 'react';
import { Header, About, Contact, Brands} from './containers';
import { Navbar, Topbar } from './components';
import './App.css'

const App = () => {
    return (
        <div className="App">
            <Topbar />
            <Navbar />
            <Header />
            <Brands />
            <About />
            <Contact />
        </div>
    )
}

export default App
